import React from 'react';
import { useCatalog, useStopLoading } from 'utils/hooks';
import PropTypes from 'prop-types';

import Head from 'components/Head';
import { SectionProvider } from 'components';

import { SubscriptionPageStyled } from './Subscriptions.styled';

import pageSectionsMap from './PageSectionsMap';

const SubscriptionPage = ({ data, location }) => {
  const navState = location?.state || {};
  const catalog = useCatalog();
  const fields = data?.contentfulLandingPage;
  const sectionsData = fields?.sections?.map((e) => ({ ...e, navState }));
  const shouldStopLoading = !!sectionsData?.length && !!Object.values(catalog.supplies)?.length;

  useStopLoading(shouldStopLoading);

  if (!data || !sectionsData) return null;

  return (
    <SubscriptionPageStyled>
      <Head
        title={fields.metaTitle}
        description={fields.metaDescription?.metaDescription}
        ogImage={fields.metaFogImage?.file?.url}
        twitterImage={fields.metaTwitterImage?.file?.url}
        canonicalRoute={fields.slug}
      />
      <SectionProvider sectionContent={sectionsData} customSectionMap={pageSectionsMap} isGraphqlData />
    </SubscriptionPageStyled>
  );
};

SubscriptionPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default SubscriptionPage;
