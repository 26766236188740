import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Text, GatsbyImage } from 'elements';

import { BackdropColor, Feature, FeaturedContainer, FeaturesWrapper } from './FeaturedThree.styled';

const FeaturedThree = ({ title, features, image }) => {
  const renderFeature = (feature, index) => {
    const featNumber = `${index < 10 ? '0' : ''}${index + 1}`;
    return (
      <Feature key={index} modifiers={['alignStart']}>
        <Text className='FeatNumber' modifiers={['brandFont', 'semibold', 'whiteColor']}>
          {featNumber}
        </Text>
        <Text className='FeatCopy' modifiers={['brandFont', 'bold', 'left', 'xLarge', 'whiteColor']}>
          {feature.text}
        </Text>
      </Feature>
    );
  };

  return (
    <FeaturedContainer>
      <ContentSection modifiers={['wBackgroundImage', 'leftText']}>
        {image && <GatsbyImage image={image} />}
        <BackdropColor className='Backdrop' />
        <Text className='Title' modifiers={['brandFont', 'medium', 'bold', 'xLarge', 'whiteColor']}>
          {title}
        </Text>
        <FeaturesWrapper modifiers={['directionColumn', 'alignCenter']}>{features?.map?.(renderFeature)}</FeaturesWrapper>
      </ContentSection>
    </FeaturedContainer>
  );
};

FeaturedThree.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
  image: PropTypes.object,
};

export default FeaturedThree;
