import styled from 'styled-components';

import breakPoints from 'theme/Media';

export const SkipSectionWrapper = styled.div.attrs(() => ({ className: 'SkipSectionWrapper' }))`
  background-color: ${({ theme }) => theme.farmProject.gray_2};

  .ContentSection {
    padding: 2rem 1.5rem;
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .Text {
      line-height: normal;
      letter-spacing: normal;
    }

    .Title {
      margin-bottom: 0.5rem;
    }

    .Subtitle {
      margin-bottom: 2rem;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .Title {
        margin-bottom: 0.5rem;
      }

      .Subtitle {
        /* font-size: 1.25rem; */
      }
    }
  }
`;
