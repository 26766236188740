import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';

import { SquaredButton, Text } from 'elements';
import { ContentSection, Flex } from 'components';

import { SkipSectionWrapper } from './SkipSection.styled';

import { setCartValue } from 'app/reduxState/cart';

const SkipSection = ({ title, subtitle, cta }) => {
  const dispatch = useDispatch();

  const onSkipPage = () => {
    dispatch(setCartValue({ label: 'forceCartPreviewFetchOnCheckout', value: true }));
    navigate(cta?.linkUrl);
  };

  return (
    <SkipSectionWrapper>
      <ContentSection>
        <Flex modifiers={['alignCenter', 'directionColumn']}>
          <Text className='Title' modifiers={['brandFont', 'medium', 'semibold']}>
            {title}
          </Text>
          <Text className='Subtitle' modifiers={['brandFont', 'xLarge', 'light']}>
            {subtitle}
          </Text>
          <SquaredButton label={cta?.linkText} onClick={onSkipPage} />
        </Flex>
      </ContentSection>
    </SkipSectionWrapper>
  );
};

SkipSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.object,
};

export default SkipSection;
