import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Icon, Text } from 'elements';

import { Feature, FeaturedContainer, FeaturesWrapper } from './FeaturedOne.styled';

import Check from 'elements/icon/assets/circle-check.svg';

const FeaturedOne = ({ title, features }) => {
  const renderFeature = (feature, index) => {
    return (
      <Feature key={index} modifiers={['alignCenter']}>
        <Icon>
          <Check />
        </Icon>
        <Text modifiers={['brandFont', 'semibold', 'left', 'xLarge']}>{feature.text}</Text>
      </Feature>
    );
  };

  return (
    <FeaturedContainer>
      <ContentSection modifiers='leftText'>
        <Text className='Title' modifiers={['brandFont', 'medium', 'semibold', 'xLarge']}>
          {title}
        </Text>
        <FeaturesWrapper modifiers={['directionColumn', 'alignCenter']}>{features?.map?.(renderFeature)}</FeaturesWrapper>
      </ContentSection>
    </FeaturedContainer>
  );
};

FeaturedOne.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
};

export default FeaturedOne;
