import styled from 'styled-components/macro';

import { Flex } from 'components';
import breakPoints from 'theme/Media';

export const FeaturedContainer = styled.div`
  .ContentSection {
    padding: 2rem 2rem;
    background-color: ${({ theme }) => theme.farmProject.gray_3};

    .SectionInner {
      margin: auto;
      max-width: 95rem;
    }
    .Text {
      line-height: normal;
    }
    .Title {
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 3rem 2rem;
      .Title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
      }
    }
  }
`;

export const FeaturesWrapper = styled(Flex).attrs(() => ({ className: 'FeaturesWrapper' }))`
  @media ${breakPoints.middle_break} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const Feature = styled(Flex)`
  width: 100%;
  max-width: 25rem;
  margin-bottom: 2rem;

  .Icon {
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
  }
  .FeatureTitle {
    margin: 0.6rem 0 0.15rem;
  }

  @media ${breakPoints.large_break} {
    max-width: 18.125rem;
    .Icon {
      width: 1.8rem;
      height: 1.8rem;
    }
    .Text {
      font-size: 1.25rem;
    }
  }
`;
