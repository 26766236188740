import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { Flex } from 'components';

import breakPoints from 'theme/Media';

const cardModifiers = {
  selected: ({ theme }) => ({
    styles: `
      box-shadow: 0px 4px 9px 4px ${theme.farmProject.dark_green}5a;
      border: 2px solid ${theme.farmProject.dark_green};
    `,
  }),
  highlighted: () => ({
    styles: `
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    `,
  }),
};

const dividerModifiers = {
  selected: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_green};
    `,
  }),
};

const highlightModifiers = {
  selected: ({ theme }) => ({
    styles: `
      border: 2px solid ${theme.farmProject.dark_green};
      background-color: ${theme.farmProject.dark_green};
      box-shadow: 0px -3px 9px 2px ${theme.farmProject.dark_green}5a;

      .Text {
        margin-top: 2px;
      }
    `,
  }),
};

export const PlanCardWrapper = styled(Flex)`
  cursor: pointer;
  position: relative;
  padding: 0.875rem 0.625rem;
  border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.farmProject.white};

  .Text {
    letter-spacing: normal;
    &:not(.DollarsPriceText) {
      line-height: normal;
    }
  }

  .PeriodText {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    margin-bottom: 0.75rem;

    .MonthsText {
      margin-left: 0.25rem;
    }
  }

  .SquaredButton {
    height: 1.5rem;
  }

  @media ${breakPoints.medium_break} {
    width: 8.5rem;
    padding: 1rem 0.75rem;

    .PeriodText {
      font-size: 1.125rem;
      * {
        font-size: 1.125rem;
      }

      .MonthsText {
        margin-left: 0.4rem;
      }
    }
  }

  @media ${breakPoints.large_break} {
    width: 12rem;
    padding: 1.9rem 1.5rem;
    border-radius: 2px;

    .PeriodText {
      font-size: 2rem;
      margin-bottom: 1rem;
      * {
        font-size: 2rem;
      }

      .MonthsText {
        margin-left: 0.4rem;
      }
    }

    .SquaredButton {
      height: 2.15rem;
    }
  }

  ${applyStyleModifiers(cardModifiers)};
`;

export const PriceWrapper = styled(Flex)`
  margin-top: 1rem;

  .CurrencyText {
    font-size: 0.625rem;
  }
  .DollarsPriceText {
    font-size: 2.5rem;
    line-height: 2rem;
  }
  .CentsPriceText {
    font-size: 0.875rem;
  }
  .PricePeriodText {
    font-size: 0.625rem;
  }

  @media ${breakPoints.medium_break} {
    margin-top: 1.25rem;
    .CurrencyText {
      font-size: 0.875rem;
    }
    .DollarsPriceText {
      font-size: 3rem;
      line-height: 2.4rem;
    }
    .CentsPriceText {
      font-size: 1rem;
    }
    .PricePeriodText {
      font-size: 0.75rem;
    }
  }

  @media ${breakPoints.large_break} {
    margin-top: 1.5rem;
    .CurrencyText {
      font-size: 1.5rem;
    }
    .DollarsPriceText {
      font-size: 5rem;
      line-height: 4.1rem;
    }
    .CentsPriceText {
      font-size: 1.75rem;
    }
    .PricePeriodText {
      font-size: 1.125rem;
    }
  }
`;

export const DiscountedPriceRow = styled(Flex)`
  margin: 0.625rem 0 1rem;

  @media ${breakPoints.large_break} {
    margin: 1.5rem 0 2rem;
  }
`;

export const OriginalPriceWrapper = styled.div`
  position: relative;

  .OriginalPrice {
    font-size: 0.65rem;
  }

  @media ${breakPoints.medium_break} {
    .OriginalPrice {
      font-size: 0.875rem;
    }
  }

  @media ${breakPoints.large_break} {
    .OriginalPrice {
      font-size: 1.25rem;
    }
  }
`;

export const CutPriceLine = styled.div`
  top: 49%;
  left: 50%;
  position: absolute;
  width: calc(100% + 2px);
  transform: translateX(-50%) rotate(10.49deg);
  border: 0.5px solid ${({ theme }) => theme.farmProject.red};
`;

export const DiscountPriceWrapper = styled(Flex)`
  padding: 0.25rem 0.3rem;
  margin-left: 0.5rem;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .DiscountPrice {
    font-size: 0.625rem;
  }

  @media ${breakPoints.medium_break} {
    .DiscountPrice {
      font-size: 0.75rem;
    }
  }

  @media ${breakPoints.large_break} {
    padding: 0.25rem 1rem;
    margin-left: 1rem;

    .DiscountPrice {
      font-size: 0.875rem;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.farmProject.dark_gray};

  ${applyStyleModifiers(dividerModifiers)};
`;

export const HightlightWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 1.25rem;
  transform: translateY(-100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
  background-color: ${({ theme }) => theme.farmProject.dark_gray};

  .Text {
    font-size: 0.75rem;
  }

  @media ${breakPoints.large_break} {
    height: 2.5rem;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    .Text {
      font-size: 1.5rem;
    }
  }

  ${applyStyleModifiers(highlightModifiers)};
`;
