export const LgType = {
  HARVEST: {
    LANDING: 'Harvest:Landing',
    FAQ: 'Harvest:FAQ',
    FEATURED_SEEDLINGS: 'Harvest:FeaturedSeedlings',
    HERO: 'Harvest:Hero',
    PODCAST: 'Harvest:Podcast',
    QUOTE: 'Harvest:Quote',
    RECIPES: 'Harvest:Recipes',
    SEEDLING_PLAN: 'Harvest:Shop:SeedlingPlan',
    SHOP: 'Harvest:Shop',
    SHOP_FARM_BUNDLES: 'Harvest:ShopFarmBundles',
    SHOP_BUNDLES: 'Harvest:ShopBundles',
    SHOP_REDEMPTION: 'Harvest:ShopRedemption',
    SMOOTHIES: 'Harvest:Smoothies',
    TERMS: 'Harvest:Terms',
    VIDEO: 'Harvest:Video',
    ZIP: 'Harvest:Shop:Zip',
    REDEMPTION_INPUT: 'Harvest:Shop:Redemption',
  },
  SUBSCRIPTION: {
    PLANS: 'Subscription:PLANS',
    SKIP_SECTION: 'Subscription:SkipSection',
    ORIGINAL_PRICE: 'Subscription:OriginalPrice',
    FAQ: 'Subscription:FAQ',
    FEATURED_ONE: 'Subscription:FeaturedOne',
    FEATURED_TWO: 'Subscription:FeaturedTwo',
    FEATURED_TWO_BLOCKS: {
      ONE: 'Subscription:FeaturedTwo:One',
      TWO: 'Subscription:FeaturedTwo:Two',
      THREE: 'Subscription:FeaturedTwo:Three',
    },
    FEATURED_THREE: 'Subscription:FeaturedThree',
  },
};
