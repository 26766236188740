import styled from 'styled-components/macro';

import { Flex } from 'components';
import breakPoints from 'theme/Media';

export const FeaturedContainer = styled.div`
  .ContentSection {
    padding: 2rem 2rem;

    .SectionInner {
      .Image {
        picture {
          img {
            width: 100%;
          }
        }
      }
      margin: auto;
      max-width: 95rem;
    }
    .Text:not(.FeatNumber) {
      line-height: normal;
    }
    .Title {
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 3rem 2rem;
      .Title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
      }
    }
  }
`;

export const FeaturesWrapper = styled(Flex).attrs(() => ({ className: 'FeaturesWrapper' }))`
  @media ${breakPoints.middle_break} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const Feature = styled(Flex)`
  width: 100%;
  max-width: 17rem;
  margin-bottom: 1.25rem;

  .FeatNumber {
    flex-shrink: 0;
    width: 3.5rem;
    font-size: 2.5rem;
    line-height: 2.4rem;
  }
  .FeatCopy {
    margin-left: 0.4rem;
  }

  @media ${breakPoints.middle_break} {
    .FeatCopy {
      margin-left: 0.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    .FeatCopy {
      font-size: 1.25rem;
      margin-left: 0.5rem;
    }
  }
`;

export const BackdropColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.farmProject.dark_green}BF;
`;
