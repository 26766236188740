import styled from 'styled-components/macro';

import { Flex } from 'components';
import breakPoints from 'theme/Media';

export const FeaturedContainer = styled.div`
  .ContentSection {
    padding: 2rem 2rem;
    background-color: ${({ theme }) => theme.farmProject.gray_6};

    .SectionInner {
      margin: auto;
      max-width: 85rem;
    }
    .Text {
      line-height: normal;
    }
    .Title {
      font-size: 1.125rem;
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 3rem 2rem;
      .Title {
        font-size: 2rem;
        margin-bottom: 2.5rem;
      }
    }
  }
`;

export const FeaturesWrapper = styled(Flex).attrs(() => ({ className: 'FeaturesWrapper' }))`
  @media ${breakPoints.middle_break} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const Feature = styled(Flex)`
  width: 100%;
  max-width: 17rem;
  margin-bottom: 1.25rem;

  .Text {
    margin-left: 1.25rem;
  }

  .Icon {
    flex-shrink: 0;
    width: 1.4rem;
    height: 1.4rem;

    svg {
      width: 95%;
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
      path + path {
        fill: white;
      }
    }
  }

  @media ${breakPoints.middle_break} {
    align-items: flex-start;

    .Text {
      margin-left: 0.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    .Icon {
      width: 1.8rem;
      height: 1.8rem;
    }

    .Text {
      font-size: 1.25rem;
      margin-left: 0.5rem;
    }
  }
`;
