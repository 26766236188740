/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

import { Featured } from 'components';
import PlansSection from './components/plans-section/PlansSection';
import SkipSection from './components/skip-section/SkipSection';
import FeaturedOne from './components/featured-one/FeaturedOne';
import FeaturedTwo from './components/featured-two/FeaturedTwo';
import FeaturedThree from './components/featured-three/FeaturedThree';

import { LgType } from 'constants/LgType';
import { getContentfulEntryLgType } from 'utils/contentful-utils';

const { PLANS, SKIP_SECTION, ORIGINAL_PRICE, FEATURED_ONE, FEATURED_TWO, FEATURED_THREE, FAQ } = LgType.SUBSCRIPTION;

const PageSectionsMap = {
  [PLANS]: ({ title, lowerTitle, body, body2, percentages }) => {
    const originalPrice = +percentages?.find?.((e) => getContentfulEntryLgType(e, true) === ORIGINAL_PRICE)?.title;
    return (
      <PlansSection
        key={PLANS}
        title={title}
        subtitle={lowerTitle}
        plansTitle={body?.body}
        footnote={body2?.body2}
        originalPrice={originalPrice}
      />
    );
  },
  [FEATURED_ONE]: ({ title, percentages }) => {
    return <FeaturedOne key={FEATURED_ONE} title={title} features={percentages} />;
  },
  [FEATURED_TWO]: ({ title, percentages }) => {
    const features = percentages?.map?.((e) => ({
      title: e.title,
      body: e.body?.body,
      lgTypeId: e.lgType?.lgTypeId,
    }));
    return <FeaturedTwo title={title} features={features} />;
  },
  [FEATURED_THREE]: ({ title, percentages, mediaBackground }) => {
    const gatsbyImageData = mediaBackground?.[0]?.gatsbyImageData || null;
    return <FeaturedThree title={title} features={percentages} image={gatsbyImageData} />;
  },
  [FAQ]: ({ title, percentages }) => {
    const faqItems = percentages?.map?.((item) => ({ fields: { ...item, body: item.body?.body } }));
    return <Featured key={FAQ} title={title} percentages={faqItems} modifiers='gray_6' />;
  },
  [SKIP_SECTION]: ({ title, headline, primaryButton, navState }) => {
    return navState?.displaySkipSection && <SkipSection key={SKIP_SECTION} title={title} subtitle={headline} cta={primaryButton?.fields} />;
  },
};

export default PageSectionsMap;
