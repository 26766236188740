import React from 'react';
import PropTypes from 'prop-types';

import { SquaredButton, Text } from 'elements';

import {
  PlanCardWrapper,
  Divider,
  PriceWrapper,
  DiscountedPriceRow,
  OriginalPriceWrapper,
  DiscountPriceWrapper,
  CutPriceLine,
  HightlightWrapper,
} from './PlanCard.styled';
import { Flex } from 'components';
import { formatPrice } from 'utils/cart-utils';

const PlanCard = ({ plan: { period, monthlyPrice }, originalPrice, isSelected, isHighlighted, highlightLabel, onSelectPlan }) => {
  const [dollarsPrice, centsPrice] = formatPrice(monthlyPrice, 2, false)?.split('.');
  const selectedModifier = isSelected && 'selected';
  const highlightedModifier = isHighlighted && 'highlighted';
  const selectedTextColor = isSelected ? 'darkGreenColor' : '';
  const discount = ((1 - monthlyPrice / originalPrice) * 100).toFixed(0);

  return (
    <PlanCardWrapper
      className='PlanCardWrapper'
      modifiers={['directionColumn', 'alignCenter', selectedModifier, highlightedModifier]}
      onClick={onSelectPlan}
    >
      {isHighlighted && (
        <HightlightWrapper modifiers={['alignCenter', 'justifyCenter', selectedModifier]}>
          <Text modifiers={['brandFont', 'inverted', 'semibold']}>{highlightLabel}</Text>
        </HightlightWrapper>
      )}
      <Text className='PeriodText' modifiers={['brandLight', selectedTextColor]}>
        {period}
        <Text className='MonthsText' modifiers={['brandLight', selectedTextColor]}>
          {' months'}
        </Text>
      </Text>
      <Divider modifiers={[selectedModifier]} />
      <PriceWrapper>
        <Text className='CurrencyText' modifiers={['brandLight', selectedTextColor]}>
          $
        </Text>
        <Text className='DollarsPriceText' modifiers={['brandLight', selectedTextColor]}>
          {dollarsPrice}
        </Text>
        <Flex modifiers='directionColumn'>
          <Text className='CentsPriceText' modifiers={['brandLight', selectedTextColor]}>
            .{centsPrice}
          </Text>
          <Text className='PricePeriodText' modifiers={['brandLight', selectedTextColor]}>
            /mth
          </Text>
        </Flex>
      </PriceWrapper>
      <DiscountedPriceRow modifiers={['alignCenter']}>
        <OriginalPriceWrapper>
          <CutPriceLine />
          <Text className='OriginalPrice' modifiers={['brandLight', 'lightGrayColor']}>
            {formatPrice(originalPrice)}
          </Text>
        </OriginalPriceWrapper>
        <DiscountPriceWrapper>
          <Text className='DiscountPrice' modifiers={['brandLight', selectedTextColor]}>
            SAVE {discount}%
          </Text>
        </DiscountPriceWrapper>
      </DiscountedPriceRow>
      <SquaredButton label='SELECT' modifiers={['fluid', isSelected && 'quaternary']} onClick={onSelectPlan} />
    </PlanCardWrapper>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
  originalPrice: PropTypes.number,
  isSelected: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  highlightLabel: PropTypes.string,
  onSelectPlan: PropTypes.func,
};

PlanCard.defaultProps = {
  plan: {},
  originalPrice: 55.0,
  isSelected: false,
  isHighlighted: false,
  highlightLabel: 'BEST VALUE',
};

export default PlanCard;
