import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Icon, Text } from 'elements';

import { Feature, FeaturedContainer, FeaturesWrapper } from './FeaturedTwo.styled';

import Clock from 'elements/icon/assets/clock-arrow.svg';
import Gear from 'elements/icon/assets/gear.svg';

import { LgType } from 'constants/LgType';

const { ONE, TWO, THREE } = LgType.SUBSCRIPTION.FEATURED_TWO_BLOCKS;

const mapIconToFeature = {
  [ONE]: <Clock />,
  [TWO]: <Gear />,
  [THREE]: <Clock />,
};

const FeaturedTwo = ({ title, features }) => {
  const renderFeature = (feature, index) => {
    const icon = mapIconToFeature[feature.lgTypeId];
    return (
      <Feature key={index} modifiers={['directionColumn', 'alignCenter']}>
        <Icon>{icon}</Icon>
        <Text className='FeatureTitle' modifiers={['brandFont', 'semibold', 'xLarge']}>
          {feature.title}
        </Text>
        <Text modifiers={['brandFont', 'light', 'xLarge']}>{feature.body}</Text>
      </Feature>
    );
  };

  return (
    <FeaturedContainer>
      <ContentSection>
        <Text className='Title' modifiers={['brandFont', 'medium', 'semibold', 'xLarge']}>
          {title}
        </Text>
        <FeaturesWrapper modifiers={['directionColumn', 'alignCenter']}>{features?.map?.(renderFeature)}</FeaturesWrapper>
      </ContentSection>
    </FeaturedContainer>
  );
};

FeaturedTwo.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
};

export default FeaturedTwo;
