import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ContentSection, Flex } from 'components';
import { SquaredButton, Text } from 'elements';

import { ContentWrapper, Divider, PlansSectionWrapper, PriceBar, PriceBarInner, PriceWrapper } from './PlansSection.styled';

import PlanCard from './components/plan-card/PlanCard';

import { getCatalogSubscriptions } from 'app/reduxState/catalog';
import { addItem, openCartModal } from 'app/reduxState/cart';
import { formatPrice } from 'utils/cart-utils';
import { getNodeBounds } from 'utils/node-bounds-utils';
import breakPoints from 'theme/Media';

const PlansSection = ({ title, subtitle, plansTitle, footnote, originalPrice }) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(breakPoints.large_break);
  const subscriptionPlans = useSelector(getCatalogSubscriptions);
  const [priceBarTop, setPriceBarTop] = useState();
  const [selectedPlan, setSelectedPlan] = useState(-1);
  const { sku, priceCents = 0, period = 0 } = subscriptionPlans?.[selectedPlan] || {};
  const [dollarsPrice = 0, centsPrice = 0] = formatPrice(priceCents / 100, 2, false)?.split('.');

  useEffect(() => {
    const onScrollChange = () => {
      if (!isDesktop) return;
      setTimeout(() => {
        updatePriceBarPosition();
      }, 150);
    };

    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', onScrollChange);
      window.addEventListener('scroll', onScrollChange);
    }

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('scroll', onScrollChange);
    };
  }, [isDesktop]);

  const updatePriceBarPosition = () => {
    // In Desktop, makes price Bar be sticky below Navigation bar (which its position can vary)
    // In Mobile, price Bar is always sticky to the bottom.
    const navBounds = getNodeBounds('.Navigation');
    const newPosition = navBounds?.height + navBounds?.y;
    const priceBarElement = document.querySelector('.PriceBar');
    if (newPosition && priceBarElement) {
      setPriceBarTop(newPosition);
    }
  };

  const onAddToCart = () => {
    dispatch(addItem({ item: { sku: sku, qty: 1 } }));
    dispatch(openCartModal());
  };

  const renderPlanCard = (plan, index) => {
    const isSelected = index === selectedPlan;
    const isHighlighted = index === subscriptionPlans.length - 1;

    const onSelectPlan = () => {
      if (isDesktop) updatePriceBarPosition();
      setSelectedPlan(index);
    };

    return (
      <PlanCard
        key={index}
        plan={plan}
        originalPrice={originalPrice}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        onSelectPlan={onSelectPlan}
      />
    );
  };

  return (
    <PlansSectionWrapper>
      <ContentSection>
        <Text id='Title' modifiers={['brandFont', 'bold']}>
          {title}
        </Text>
        <Text id='Subtitle' modifiers={['brandLight']}>
          {subtitle}
        </Text>
        <Divider />
        <Text id='PlansTitle' modifiers={['brandFont', 'xLarge', 'semibold']}>
          {plansTitle}
        </Text>
        <ContentWrapper modifiers={['alignCenter', 'spaceBetween', 'spaceAround']}>{subscriptionPlans.map(renderPlanCard)}</ContentWrapper>
        <Text id='Footnote' modifiers={['brandLight']}>
          {footnote}
        </Text>
      </ContentSection>
      <PriceBar className='PriceBar' top={priceBarTop} show={!!sku}>
        <PriceBarInner modifiers={['flex1', 'alignCenter', 'spaceBetween']}>
          <Flex className='PriceContainer' modifiers='directionColumn'>
            <PriceWrapper>
              <Text className='CurrencyText' modifiers={['brandLight', 'darkGreenColor']}>
                $
              </Text>
              <Text className='DollarsPriceText' modifiers={['brandFont', 'semibold', 'darkGreenColor']}>
                {dollarsPrice}
              </Text>
              <Text className='CentsPriceText' modifiers={['brandFont', 'semibold', 'darkGreenColor']}>
                .{centsPrice}
              </Text>
            </PriceWrapper>
            <Text className='TotalPriceText' modifiers={['brandFont', 'semibold', 'darkGreenColor']}>
              Total price for {period} months
            </Text>
          </Flex>
          <SquaredButton label='ADD TO CART' modifiers={['quaternary']} onClick={onAddToCart} />
        </PriceBarInner>
      </PriceBar>
    </PlansSectionWrapper>
  );
};

PlansSection.propTypes = {
  plans: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  plansTitle: PropTypes.string,
  footnote: PropTypes.string,
  originalPrice: PropTypes.number,
};

export default PlansSection;
