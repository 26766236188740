import styled from 'styled-components';

import { Flex } from 'components';
import breakPoints from 'theme/Media';

export const PlansSectionWrapper = styled.div.attrs(() => ({ className: 'PlansSectionWrapper' }))`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .ContentSection {
    padding: 2rem 1rem;

    #Title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    #Subtitle {
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    #PlansTitle,
    #Footnote {
      line-height: normal;
      letter-spacing: normal;
    }
  }

  @media ${breakPoints.large_break} {
    padding: 6.5rem 1rem 2rem;
    .ContentSection {
      #Title {
        font-size: 2rem;
        margin-bottom: 0.8rem;
        letter-spacing: 1px;
      }
      #Subtitle {
        font-size: 1.125rem;
        font-weight: 500;
        max-width: 36rem;
        margin: auto;
      }
      #PlansTitle {
        font-size: 1.3rem;
      }
    }
  }
`;

export const ContentWrapper = styled(Flex)`
  width: 100vw;
  margin: 2rem 0 1.5rem -1rem;

  @media ${breakPoints.medium_break} {
    margin: 2.5rem 0 1.5rem -1rem;
  }

  @media ${breakPoints.large_break} {
    width: auto;
    max-width: 60rem;
    margin: 4rem auto 1.5rem;
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 1.5rem 0;
  border-top: 0.5px solid ${({ theme }) => theme.farmProject.dark_gray};

  @media ${breakPoints.large_break} {
    margin: 1.75rem 0;
  }
`;

export const PriceBar = styled(Flex)`
  z-index: 10;
  position: fixed;
  top: unset;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -5px 7px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  display: ${({ show }) => (show ? 'block' : 'none')};

  @media ${breakPoints.large_break} {
    margin-left: -1rem;
    z-index: 10;
    top: ${({ top, theme }) => (top ? `${top}px` : theme.layout.navHeight)};
    bottom: unset;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.1);
  }
`;

export const PriceBarInner = styled(Flex)`
  padding: 0.75rem 1rem;

  .TotalPriceText {
    line-height: normal;
    letter-spacing: normal;
  }

  @media ${breakPoints.large_break} {
    padding: 1rem 0;
    justify-content: center;

    .TotalPriceText {
      font-size: 1.125rem;
    }

    .PriceContainer {
      min-width: 13.5rem;
      margin-right: 6rem;
    }

    .Button {
      padding: 1rem 3rem;
      margin-left: 6rem;

      .Text,
      span {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: normal;
      }
    }
  }
`;

export const PriceWrapper = styled(Flex)`
  margin-bottom: 0.25rem;

  .Text {
    line-height: normal;
  }

  .CurrencyText {
    font-size: 0.75rem;
  }
  .DollarsPriceText {
    font-size: 2.5rem;
    line-height: 2rem;
  }
  .CentsPriceText {
    font-size: 1rem;
  }

  @media ${breakPoints.large_break} {
    margin-bottom: 0.5rem;
    .CurrencyText {
      font-size: 1rem;
    }
    .DollarsPriceText {
      font-size: 4rem;
      line-height: 2.9rem;
    }
    .CentsPriceText {
      font-size: 1.5rem;
    }
  }
`;
