import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SubscriptionsPage from 'staticPages/subscriptions/Subscriptions';

const Subscriptions = ({ data, location }) => {
  return <SubscriptionsPage data={data} location={location} />;
};

Subscriptions.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default Subscriptions;

export const query = graphql`
  query {
    contentfulLandingPage(contentful_id: { eq: "5485FPXVRzxzLSPaXb7GLo" }) {
      slug
      ...LandingPageMetaFragment
      sections {
        ... on ContentfulSectionBlocks {
          title
          lowerTitle
          lgType {
            lgTypeId
          }
          mediaBackground {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
          }
          ...BlocksBodyFragment
          percentages {
            ... on ContentfulShortText {
              text
            }
            ... on ContentfulSectionSimpleText {
              title
              body {
                body
              }
              lgType {
                lgTypeId
              }
            }
          }
        }
        ... on ContentfulSectionSimpleText {
          title
          headline
          lgType {
            lgTypeId
          }
          primaryButton {
            ... on ContentfulCallToAction {
              linkText
              linkUrl
            }
          }
        }
      }
    }
  }
`;
